import { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Header from "./components/Header/Header";
import triangleSVG from "./assets/triangle.svg";
import News from "./pages/News";
import Education from "./pages/Education";
import SingleBlog from "./pages/SingleBlog";
import Contact from "./pages/Contact";
import TermsAndConditions from "./pages/TermsAndConditions";
import CookiePolicy from "./pages/CookiePolicy";
import CookieModal from "./components/CookieModal/CookieModal";
import Forex from "./pages/Forex";
import FooterNew from "./components/Footer/FooterNew";
import Montenegroberza from "./pages/Montenegroberza";
import Usaberza from "./pages/Usaberza";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { initFacebookPixel } from "./helpers/pixel";

function useGoogleAnalytics() {
  const location = useLocation();

  useEffect(() => {
    // Provera da li je GTM već učitan
    if (!window.gtag) {
      // Inicijalizacija dataLayer-a
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      window.gtag = gtag; // Sačuvaj gtag u window objektu da bismo sprečili ponovno učitavanje

      // Učitavanje GTM skripte
      const scriptTag = document.createElement('script');
      scriptTag.async = true;
      scriptTag.src = 'https://www.googletagmanager.com/gtag/js?id=G-3BYSC262YF';
      document.head.appendChild(scriptTag);

      // Konfiguracija GTM nakon što je skripta učitana
      scriptTag.onload = () => {
        gtag('js', new Date());
        gtag('config', 'G-3BYSC262YF', {
          'page_path': location.pathname,
        });
      };
    } else {
      // Ako je GTM već učitan, samo ažurirajte konfiguraciju za novu lokaciju
      window.gtag('config', 'G-3BYSC262YF', {
        'page_path': location.pathname,
      });
    }

    // reCAPTCHA
    if (!window.grecaptcha) {
      const siteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;
      const reCaptchaScriptTag = document.createElement('script');
      reCaptchaScriptTag.async = true;
      reCaptchaScriptTag.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
      document.head.appendChild(reCaptchaScriptTag);
    }
  }, [location]); // Ponovo pokreće efekat na promenu lokacije
}

function App() {
  const location = useLocation();
  const [showCookieModal, setShowCookieModal] = useState(false);
  const [queryClient] = useState(() => new QueryClient());

  useGoogleAnalytics();

  useEffect(() => {
    initFacebookPixel();
  }, []);

  useEffect(() => {
    window.fbq && window.fbq("track", "PageView");
  }, [location.pathname]);

  useEffect(() => {
    setTimeout(() => {
      if (!localStorage.getItem("tf_capital_cookies")) {
        setShowCookieModal(true);
      }
    }, 1000);
  }, []);

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        const headerHeight = document.getElementById("header").offsetHeight;
        window.scrollTo({
          top:
            hash === "#map"
              ? element.offsetTop - headerHeight
              : element.offsetTop,
          behavior: "smooth",
        });
      }
    } else {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }, 0);
    }
  }, [location.pathname, location.hash]);

  const onCookieAccept = () => {
    localStorage.setItem("tf_capital_cookies", true);
    setShowCookieModal(false);
  };

  return (
    <div className="App relative">
      <QueryClientProvider client={queryClient}>
        {showCookieModal && (
          <div className="position fixed top-0 left-0 w-full h-full"></div>

        )}

        <img
          className="absolute top-0 left-0"
          src={triangleSVG}
          alt="triangle-svg"
        />
        <Header />
        <CookieModal
          show={showCookieModal}
          handleAccept={() => onCookieAccept()}
        />
        <div className="relative z-10 2xl:pt-[100px] lg:pt-[95px] pt-[92px] ">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/news" element={<News />} />
            <Route path="/education" element={<Education />} />
            <Route path="/news/:slug" element={<SingleBlog />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/forex" element={<Forex />} />
            <Route path="/usa-berza" element={<Usaberza />} />
            <Route path="/montenegroberze" element={<Montenegroberza />} />
            <Route path="/termsConditions" element={<TermsAndConditions />} />
            <Route path="/cookiePolicy" element={<CookiePolicy />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          </Routes>
        </div>
        <FooterNew />
      </QueryClientProvider>
      <noscript>
        <img
          height="1"
          width="1"
          style="display:none"
          src="https://www.facebook.com/tr?id=3634881536733211&ev=PageView&noscript=1"
        />
      </noscript>
    </div>
  );
}

export default App;
