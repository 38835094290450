import { Link, useParams } from "react-router-dom";
import {
  getBlog,
  BlogKeys,
  getSpecificNews,
} from "../api/repositories/blogRepository";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { format, parseISO } from "date-fns";
import ContentLoader from "react-content-loader";
import Newsletter from "../components/Newsletter/Newsletter";
import CapitalProducts from "../components/CapitalProducts/CapitalProducts";
import { NavLink } from "react-router-dom";
import tfTraderBanner from '../assets/bannerTfTrader.png';
import greenTriangle from '../assets/green2.png';

function SingleBlog() {
  const { slug } = useParams();
  const locale = useTranslation("home").i18n.language;

  const { data: blog, isLoading } = useQuery({
    queryKey: [BlogKeys.BLOG + "-" + slug, slug],
    queryFn: () => getBlog(slug),
  });

  const { data: suggestedNews, isLoading: isSuggestedLoading } = useQuery({
    queryKey: [BlogKeys.NEWS + "-suggested", blog?.data?.tags],
    queryFn: () =>
      blog?.data
        ? getSpecificNews(blog.data.tags, 3)
        : Promise.resolve(),
    enabled: !!blog?.data && !isLoading,
  });

  const tags = blog?.data?.tags.split(", ");

  function shuffleArray(array) {
    for (let i = array?.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const trimmedSuggestedNews = shuffleArray(suggestedNews?.data)?.slice(0, 3);

  const getNewsLabel = (label) => {
    switch (label) {
      case "Forex":
        return "Forex";
      case "USA berze":
        return locale === "me" ? "USA berze" : "USA stock";
      case "Other":
        return locale === "me" ? "Ostalo" : "Other";
      default:
        return "";
    }
  };

  const checkLinkFilter = (filter) => {
    if (filter === "usa berze") {
      return "usa+berze";
    }

    if (filter === "Other") {
      return "other";
    }

    return getNewsLabel(filter).toLocaleLowerCase();
  };

  return (
    <div className="w-full">
      <div className="w-full pb-[130px] relative z-30 mx-auto">
        <div className="w-full flex lg:flex-row flex-col lg:gap-[112px] gap-[48px]">
          {isLoading ? (
            <div className="flex-1">
              <ContentLoader />
            </div>
          ) : (
            <div className="flex-1 flex flex-col gap-[25px] lg:gap-[64px]">

              <div className="bg-[#163A69]">
                <div className="container mx-auto pt-[64px] pl-[10px]">
                  <span className="mx-[10px] lg:mx-[0px] text-[16px] leading-[30px] text-[#788497]">
                    <Link to="/" className="hover-color">
                      TF Capital {" > "}
                    </Link>
                    <Link to="/news?filter=all" className="hover-color">
                      {locale === "me" ? ` Investiranje > ` : "Investing > "}
                    </Link>
                    <Link className="font-medium hover-color" to={`/news?filter=${checkLinkFilter(tags[0])}`}>
                      {getNewsLabel(tags[0])}
                    </Link>
                  </span>

                  <div className="lg:ml-[25px] mx-[10px] lg:mx-[0px]">
                    <h1 className="blog-title sm:text-[56px] text-[44px] font-bold sm:leading-[72px] leading-[42px] text-[white] my-[32px] lg:w-[90%]">{blog.data.title[locale]}</h1>

                    <div className="flex justify-between items-center mb-[69px] mx-[10px] lg:mx-[-20px]">
                      <label className="block text-[12px] leading-[30px] font-bold text-[white]">
                        {format(parseISO(blog.data.created_at), "dd.MM.yyyy.")}
                      </label>
                      <div className="mr-[25px] flex gap-[10px]">{
                        tags.map((tag, index) => {
                          return (
                            <div className="text-[white] bg-[#3E6BE4] py-[8px] px-[16px] rounded-[100px]" key={index} id={index}>
                              {tag}
                            </div>
                          );
                        })
                      }</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="gap-[25px] lg:gap-[36px] container relative mx-auto">
                <div
                  className="lg:mt-6 mx-5 lg:mx-0 lg:w-2/4 lg:ml-[100px]"
                  dangerouslySetInnerHTML={{ __html: blog.data.content[locale] }}
                ></div>
                <div className="w-[250px] h-[640px] absolute top-0 right-[80px] lg:block hidden">
                  <img src={tfTraderBanner} alt="tf trader banner" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Newsletter />
      <div className="flex container mx-auto items-start mt-[100px]">
        <h1 className="px-[20px] text-[18px] lg:text-[44px] leading-[30px] text-[#000] font-medium ">{locale === "me" ? "Preporučeni blogovi" : "Suggested blogs"}</h1>
      </div>
      <div className="flex container flex-col lg:flex-row flex-wrap items-center mx-auto lg:items-start mt-[64px] lg:justify-center xl:justify-between gap-[30px]">
        {isSuggestedLoading || !blog || !blog.data ? (
          <div className="container mx-auto px-[16px] lg:px-[20px] pt-8">
            <ContentLoader />
          </div>
        ) : (
          suggestedNews.data &&
          suggestedNews.data.length > 0 && (
            trimmedSuggestedNews.map((blog, index) => {
              return (
                <NavLink
                  key={index}
                  to={`/news/${blog.id}`}
                  className="group sm:w-[380px] w-[310px] lg:h-[347px] flex flex-col lg:gap-[20px] gap-[12px] items-start justify-start"
                >
                  <div className="w-full h-[215px] lg:h-[215px] overflow-hidden card-gradient relative z-[4]">
                    <h2 className="font-bold text-[24px] text-white px-[21px] py-[37px]">{blog.title[locale]}</h2>
                    <div className="absolute right-2 bottom-2 w-[80px] z-[-1]">
                      <img src={greenTriangle} alt="green Triangle" />
                    </div>
                  </div>

                  <div
                    className="flex flex-col items-start gap-4"
                    style={{ alignSelf: "normal" }}
                  >
                    <div className="flex items-center gap-3">
                      {
                        tags.map((tag, index) => {
                          if (tag === 'Forex') return <div key={index} className="px-[15px] py-[5px] bg-[#C7C7C7] rounded-2xl text-[12px] text-[676767]">{tag}</div>;
                          if (tag === 'USA berze') return <div key={index} className="px-[15px] py-[5px] bg-[#2962FF] rounded-2xl text-[12px] text-white">{tag}</div>;
                          if (tag === 'Other') return <div key={index} className="px-[15px] py-[5px] bg-[#C8E64C] rounded-2xl text-[12px] text-black">{tag}</div>;
                        })
                      }
                    </div>
                    <p className="group-hover:text-[#3E6BE4] h-fit transition-colors ease-out duration-300 line-clamp-3 text-[14px] lg:text-[14px] text-[#8894A5] lg:leading-[20px] leading-[16px] font-regular">
                      {blog.short_description[locale]}
                    </p>
                  </div>
                </NavLink>
              );
            })
          )
        )}
      </div>
      <div className="lg:pt-[44px] pt-[50px] lg:pb-16 pb-12 overflow-x-hidden">
        <CapitalProducts />
      </div>
    </div>
  );
}

export default SingleBlog;
