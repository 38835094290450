import { format, parseISO } from "date-fns";
import { NavLink } from "react-router-dom";
import greenTriangle from '../../assets/green2.png';

function NewsHeroCard({ title, id, short_description, tags }) {
  function formatTitle(title) {
    let decodedTitle;

    try {
      // Decode the URL-encoded title
      decodedTitle = decodeURIComponent(title);
    } catch (error) {
      console.error("Error decoding title:", error);
      // Fall back to using the original title if decoding fails
      decodedTitle = title;
    }

    // Replace spaces with hyphens and remove dots
    const formattedTitle = decodedTitle
      .replace(/\s+/g, '-');  // Replace spaces with hyphens

    return formattedTitle;
  }

  return (
    <NavLink
      to={`/news/${formatTitle(title)}`}
      className="group w-[360px] h-fit flex flex-col lg:gap-[20px] gap-[12px] items-start"
    >
      <div className="w-full h-[215px] lg:h-[215px] overflow-hidden card-gradient relative z-[4]">
        <h2 className="font-bold text-[24px] text-white px-[21px] py-[37px]">{title}</h2>
        <div className="absolute right-2 bottom-2 w-[80px] z-[-1]">
          <img src={greenTriangle} alt="green Triangle" />
        </div>
      </div>

      <div
        className="flex flex-col items-start gap-4"
        style={{ alignSelf: "normal" }}
      >
        <div className="flex items-center gap-3">
          {
            tags.map((tag, index) => {
              if (tag === 'Forex') return <div className="px-[15px] py-[5px] bg-[#C7C7C7] rounded-2xl text-[12px] text-[676767]">{tag}</div>;
              if (tag === 'USA berze') return <div className="px-[15px] py-[5px] bg-[#2962FF] rounded-2xl text-[12px] text-white">{tag}</div>;
              if (tag === 'Other') return <div className="px-[15px] py-[5px] bg-[#C8E64C] rounded-2xl text-[12px] text-black">{tag}</div>;
            })
          }
        </div>
        <p className="group-hover:text-[#3E6BE4] h-fit transition-colors ease-out duration-300 line-clamp-3 text-[14px] lg:text-[14px] text-[#8894A5] lg:leading-[20px] leading-[16px] font-regular">
          {short_description}
        </p>
      </div>
    </NavLink>
  );
}

export default NewsHeroCard;
